@import 'animations';
@import 'app-variables';
@import 'card-variables';
@import 'colors';

.nbkc-card-section {
	position: relative;
	z-index: 1;

	.nbkc-card-container {
		min-height: 74px;
	}

	.nbkc-card {
		overflow: hidden;
		transition: height linear $cardSlideTime $cardSlideTime, opacity linear $cardSlideTime;
		max-width: $maxCardWidth;
		width: 100%;
		margin: auto;
		border-radius: 8px;
		box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
		margin-bottom: 2rem;
		cursor: pointer;
		position: relative;
		background: #fff;

		&.green-bar {
			border-left: 5px solid $green;
		}

		.row {
			&.no-space {
				margin-bottom: 0;
			}

			&.small-space {
				margin-bottom: 1rem;
			}
		}

		.nbkc-card-body {
			padding: 0.5rem 2rem 2rem 2rem;

			label {
				font-size: 16px;
				font-weight: 600;

				&.wrap {
					white-space: normal;
				}

				&.default {
					white-space: inherit;
					text-overflow: inherit;
					overflow: inherit;
				}
			}

			.card-question {
				text-align: center;
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 5rem;
				padding: 0;
			}
		}

		.nbkc-card-header {
			display: flex;
			padding: 2rem;
			font-weight: 600;

			.nbkc-card-heading {
				flex: 1;
				min-width: 0;
				flex-direction: column;

				.nbkc-card-title {
					font-size: 24px;
					font-weight: 400;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 0;
					line-height: inherit;
				}

				.nbkc-card-subtitle {
					margin-top: 5px;
					font-size: 16px;
					font-weight: 400;
					color: #505050;
				}

				.nbkc-card-title,
				.nbkc-card-subtitle {
					transition: opacity linear $cardSlideTime, color linear $cardSlideTime;
				}
			}

			.nbkc-card-controls {
				display: flex;
				align-items: baseline;
				margin-top: 5px;

				>* {
					margin-right: 3rem;
					float: left;

					&:first-child {
						margin-left: 3rem;
					}

					&:last-child {
						margin-right: 0;
					}
				}

				.collapser {
					cursor: pointer !important;
					position: relative;
					background-color: white;
					border: none;

					&.cdk-keyboard-focused {
						&::before {
							content: '';
							/* display: inline-block; */
							position: absolute;
							opacity: .12;
							left: -2px;
							top: -4px;
							width: 30px;
							height: 30px;
							border-radius: 24.5px;
							background-color: $black;
						}
					}

					&.rotate-toggle {
						transition: 250ms;
					}
				}
			}

			&.fading-heading>*:not(.nbkc-card-controls) {
				.nbkc-card-title {
					color: #aaa;
				}

				.nbkc-card-subtitle {
					color: #aaa;
					opacity: 0;
				}
			}
		}

		.remove-block {
			margin-left: -5px;
			button {
				font-size: 16px;
			}
		}

		&.questions {
			border: 0;
			margin: 5rem auto;
			max-width: 536px;
			width: 100%;

			.nbkc-card-body {
				padding: 3rem 2rem 2rem 2rem;
			}
		}

		.whos-stuff-row {
			margin-bottom: 2rem;
		}

		.conversation-card-btn {
			width: 100%;
			max-width: 300px;
			border-radius: 150px;
			margin: 0 auto 3rem;
			padding: 3rem 1.6rem;
			overflow: hidden;
			white-space: pre-wrap;
			font-size: 16px;
			display: block;
			line-height: 0.188rem;

		}
	}

	.nbkc-card.ng-enter {
		opacity: 0;

		.nbkc-card-header {
			.nbkc-card-heading {
				color: #fff;
			}
		}
	}

	.nbkc-card.ng-enter-active {
		opacity: 1;
	}

	.nbkc-card.deleted {
		display: none;
	}

	.nbkc-card.deleted,
	.nbkc-card.deleting {
		opacity: 0;

		.nbkc-card-header {
			.nbkc-card-heading {
				.nbkc-card-title,
				.nbkc-card-subtitle {
					color: #aaa;
					text-decoration: line-through;
				}

				.nbkc-card-subtitle {
					display: none;
				}
			}
		}
	}

	.nbkc-card.empty-card {
		text-align: center;
		cursor: default;

		.nbkc-card-header {
			.nbkc-card-heading {
				.nbkc-card-title {
					max-width: 100%;
				}
			}
		}

		&.ng-leave {
			transition: $cardFadeTime linear opacity;
			opacity: 1;
			height: 0;
			margin: 0;
		}

		&.ng-enter {
			transition: $cardFadeTime linear all;
			opacity: 0;
		}

		&.ng-enter-active {
			opacity: 1;
		}
	}

	.nbkc-card:not(.expanded) {
		.nbkc-card-body {
			display: block;
			position: absolute;
			visibility: hidden;
			width: 100%;
		}
	}

	.nbkc-card.expanded {
		cursor: default;

		.nbkc-card-header {
			.nbkc-card-controls {
				.collapser {
					background-color: white;
					border: none;

					&.rotate-toggle {
						transform: rotate(-180deg);
					}
				}
			}
		}

		&.fade-heading {
			.nbkc-card-header {
				padding: 2rem;

				.nbkc-card-heading {
					.nbkc-card-title {
						color: #aaa;
					}

					.nbkc-card-subtitle {
						display: none;
					}
				}
			}
		}
	}

	#loanPurposeLabel {
		padding-bottom: 2rem; 
		color: #aaa;
		font-size: 24px; 
		font-weight: 400;
		margin-top: -4px; 
	}

	.nbkc-card.invalid:not(.new) {
		.loan-app-required.loan-app-invalid {
			&::before {
				border-color: $red $red transparent transparent;
			}

			input,
			select {
				border: 1px solid $red;
			}

			.field-error-text {
				display: inherit;

				&.field-error-text-table {
					display: table;
				}
			}
		}
	}

	.nbkc-section-heading {
		background: #fff;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 3rem;
		max-width: $maxCardWidth;
		width: 100%;
		position: relative;

		.nbkc-section-title {
			font-size: 32px;
			font-weight: 800;
			margin-bottom: 0.5rem;
			margin-top: 0;
			line-height: inherit;
		}

		.nbkc-section-subheading {
			a:focus {
				text-decoration: underline;
			}
		}
	}

	.inline-button-container {
		max-width: $maxCardWidth;
		margin: 2rem auto 6rem auto;

		.row {
			margin-right: -15px;
		}

		.mat-mdc-raised-button {
			font-size: 16px;
			font-weight: 400;
			width: 300px;
			height: 5rem;
			margin-bottom: 1rem;

			i {
				margin-right: 10px;
			}
		}
	}

	.inline-button-container-purpose {
		max-width: $maxCardWidth;
		margin: 2rem auto 2rem auto;

		.row {
			margin-right: -15px;
		}

		.mat-mdc-raised-button {
			font-size: 16px;
			font-weight: 400;
			width: 300px;
			height: 5rem;
			margin-bottom: 1rem;

			i {
				margin-right: 10px;
			}
		}
	}

	@mixin card-title-max-width($existingCollapsed, $existingExpanded, $newWidth) {
		.nbkc-card {
			.nbkc-card-header {
				.nbkc-card-heading {
					.nbkc-card-title,
					.nbkc-card-subtitle {
						max-width: $existingCollapsed;
					}
				}
			}
		}

		.nbkc-card.expanded {
			.nbkc-card-header {
				.nbkc-card-heading {
					.nbkc-card-title,
					.nbkc-card-subtitle {
						max-width: $existingExpanded;
					}
				}
			}
		}

		.nbkc-card.new {
			.nbkc-card-header {
				.nbkc-card-heading {
					.nbkc-card-title,
					.nbkc-card-subtitle {
						max-width: $newWidth;
					}
				}
			}
		}

		.nbkc-card.new.expanded {
			.nbkc-card-header {
				.nbkc-card-heading {
					.nbkc-card-title,
					.nbkc-card-subtitle {
						max-width: $newWidth;
					}
				}
			}
		}
	}

	@media only screen and (max-width: 1256px) {
		@include card-title-max-width(calc(100vw - #{$leftNavWidth} - 32rem), calc(100vw - #{$leftNavWidth} - 45rem), calc(100vw - #{$leftNavWidth} - 25rem));
	}

	@media only screen and (max-width: 991px) {
		@include card-title-max-width(calc(100vw - #{$leftNavTabletWidth} - 32rem), calc(100vw - #{$leftNavTabletWidth} - 45rem), calc(100vw - #{$leftNavTabletWidth} - 25rem));
	}

	@media only screen and (max-width: 767px) {
		.nbkc-card {
			.nbkc-card-header {
				justify-content: space-between;

				.nbkc-card-heading {
					display: flex;

					.nbkc-card-title {
						display: block;
					}
				}

				.nbkc-card-controls {
					>* {
						margin-right: 2rem;
					}
				}
			}

			.nbkc-card-body {
				label {
					&.mobile-wrap {
						white-space: normal;
					}
				}

				.card-question {
					max-width: 100%;
				}
			}

			&.questions {
				margin: 0 auto;
			}
		}

		@include card-title-max-width(calc(100vw - 20rem), calc(100vw - 30rem), calc(100vw - 13rem));
	}

	@media only screen and (max-width: 479px) {
		@include card-title-max-width(calc(100vw - 20rem), calc(100vw - 20rem), calc(100vw - 13rem));
	}
}

.modern-browser-notification {
	background: #ebebeb;
	height: 85px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 500;

	a {
		text-decoration: none;
		color: $black;
	}

	.modern-browser-message-wrap {
		width: 980px;
		margin: 32px auto;

		.modern-browser-message {
			margin-right: 15px;
		}

		.browser-recommendation {
			margin-right: 10px;
		}

		.modern-browser-message,
		.browser-recommendation {
			display: inline-block;
		}

		.browser-recommendation-image,
		.browser-recommendation-name {
			display: inline-block;
			vertical-align: middle;
		}

		.browser-recommendation-image {
			font-size: 2.9rem;
		}
	}

	.modern-browser-dismiss {
		position: absolute;
		right: 48px;
		top: 36px;
		cursor: pointer;
	}
}

@media only screen and (max-width: 1040px) {
	.modern-browser-notification {
		.modern-browser-message-wrap {
			width: 610px;

			.browser-recommendation-name {
				display: none;
			}
		}
	}
}

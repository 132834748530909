@import 'app-variables';

// fadein
@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

// fadeout

@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

/* Internet Explorer */
@-ms-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.up-down-animation.lock,
.up-down-animation.ng-animate.ng-enter {
	height: calc(100vh - #{$tallHeaderHeight});
	overflow: hidden;
}

@media only screen and (max-width: 959px) {
	.up-down-animation.lock,
	.up-down-animation.ng-animate.ng-enter {
		height: calc(100vh - #{$shortHeaderHeight});
		overflow: hidden;
	}
}

.down.up-down-animation.ng-enter,
.down.up-down-animation.ng-leave,
.up.up-down-animation.ng-enter,
.up.up-down-animation.ng-leave {
	transition: margin ease-in-out 0.8s;
}

//up
.up.up-down-animation.ng-enter {
	margin-top: -100vh;
	height: 100vh;

	&.ng-enter-active {
		margin-top: 0vh;
	}
}

.up.up-down-animation.ng-leave.ng-leave-active {
	margin-top: 0vh;
}

//down
.down.up-down-animation.ng-enter {
	margin-top: 0vh;
}

.down.up-down-animation.ng-leave.ng-leave-active {
	margin-top: -100vh;
	height: 100vh;
}

.down.up-down-animation.ng-leave,
.down.up-down-animation.ng-enter.ng-enter-active {
	margin-top: 0vh;
}

@keyframes slide-in {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0%);
	}
}

/* Firefox < 16 */
@-moz-keyframes slide-in {
	from {
		-moz-transform: translateX(-100%);
	}

	to {
		-moz-transform: translateX(0%);
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-in {
	from {
		-webkit-transform: translateX(-100%);
	}

	to {
		-webkit-transform: translateX(0%);
	}
}

/* Internet Explorer */
@-ms-keyframes slide-in {
	from {
		-ms-transform: translateX(-100%);
	}

	to {
		-ms-transform: translateX(0%);
	}
}

/* Opera < 12.1 */
@-o-keyframes slide-in {
	from {
		-o-transform: translateX(-100%);
	}

	to {
		-o-transform: translateX(0%);
	}
}

@keyframes slide-out {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}

/* Firefox < 16 */
@-moz-keyframes slide-out {
	from {
		-moz-transform: translateX(0%);
	}

	to {
		-moz-transform: translateX(-100%);
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-out {
	from {
		-webkit-transform: translateX(0%);
	}

	to {
		-webkit-transform: translateX(-100%);
	}
}

/* Internet Explorer */
@-ms-keyframes slide-out {
	from {
		-ms-transform: translateX(0%);
	}

	to {
		-ms-transform: translateX(-100%);
	}
}

/* Opera < 12.1 */
@-o-keyframes slide-out {
	from {
		-o-transform: translateX(0%);
	}

	to {
		-o-transform: translateX(-100%);
	}
}

@import 'app-variables';

.nbkc-loader-wrap {
	background: #1075F1;
	z-index: 5000;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	opacity: 1;
	overflow: hidden;

	.nbkc-loader-body {
		z-index: 100;
		margin: auto auto 1rem;
		width: 100%;
		height: 100%;
		max-height: 225px;
		padding-top: 1rem;
		text-align: center;
	}

	.nbkc-bike {
		background: url('#{$nbkc-assets-path}/img/nbkc-bike-white.svg') no-repeat center center / contain;
		width: 100%;
		height: 100%;
		max-height: 150px;

		&.blue {
			background: url('#{$nbkc-assets-path}/img/nbkc-bike-blue.svg') no-repeat center center / contain;
		}
	}

	&.internal {
		z-index: 999; // behind the main ready loader
		background: rgba(255, 255, 255, 0.8);
		visibility: hidden;
		opacity: 0;
		transition: 0.33s linear;

		.nbkc-loader-body {
			margin: 0 auto;
		}

		.nbkc-bike {
			max-height: 100px;
		}

		.saving {
			font-size: 2.4rem;
		}
	}

	.nbkc-loader {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem;
		height: 100%;
	}

	.nbkc-loader-centered {
		justify-content: center;
	}

	&.nbkc-visible {
		visibility: visible;
		opacity: 1;
		transition: 0.125s linear;
	}

	&.nbkc-hidden {
		visibility: hidden;
		opacity: 0;
		transition: 0.5s 1s linear;
	}

	.nbkc-loader-block {
		width: 100%;
		height: 100%;
		position: absolute;
		top: $tallHeaderHeight;
		left: 0;
		background: #fff;
	}

	.nbkc-loader-header {
		background: url('#{$nbkc-assets-path}/img/logo-w.svg') no-repeat center center/contain;
		width: 50%;
		height: 100%;
		max-height: 208px;
		margin: auto auto 0;
	}

	.nbkc-loader-status {
		margin: 5rem 0 0 0;
	}

	@media only screen and (max-width: 991px) {
		.nbkc-loader-block {
			top: $shortHeaderHeight;
		}
	}

	@media only screen and (max-width: 767px) {
		.nbkc-bike {
			max-height: 100px;
		}

		.nbkc-loader-header {
			width: 80%;
		}
	}
}

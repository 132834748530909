@import 'colors';

.loan-app-modal {
	.modal-dialog {
		top: 30vh;
	}

	.modal-content {
		border-radius: 3px;
		border: 0;
	}

	.loan-app-modal-main-content {
		.loan-app-modal-header {
			margin-bottom: 2rem;
			padding: 20px 20px 0 20px;
			font-size: 1.8rem;
			font-weight: 600;
		}

		.loan-app-modal-body {
			padding: 20px 20px 40px 20px;
			font-size: 1.4rem;
		}

		.loan-app-modal-footer {
			background-color: #efefef;
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
			text-align: right;
			padding: 10px 20px 10px 0;
		}

		.loan-app-action-button {
			margin: 0.5rem 0 0.5rem 1rem;

			&.mat-mdc-button-base{
				font-size: 16px;
				padding: 0.4rem 3.1rem;
			}

			&.loan-app-button-text {
				background-color: #efefef;
				padding: 1rem 1.5rem;
			}
		}
	}
}

.modal-backdrop {
	background: $darkest-gray;
	&.show{
		z-index: 300 !important;
	}
}

@import 'colors';
@import 'app-variables';

.field-error-text {
	top: 4px;
	color: $red;
	font-size: 12px;
	display: none;
	position: relative;

	&.field-error-text-table {
		display: table;
	}
}

.checkbox-wrap {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: left;
	padding-top: 1rem;

	/* iOS Safari */
	-webkit-touch-callout: none;

	/* Safari */
	-webkit-user-select: none;

	/* Konqueror HTML */
	-khtml-user-select: none;

	/* Firefox */
	-moz-user-select: none;

	/* Internet Explorer/Edge */
	-ms-user-select: none;

	/* Non-prefixed version, currently supported by Chrome and Opera */
	user-select: none;

	.checkbox-display {
		color: white;
		background: white;
		width: 25px;
		height: 25px;
		font-size: 16px;
		border: 2px solid #3c3e3f;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;

		&.its-checked {
			color: white;
			background: $nbkc-blue;
			border: none;
		}

		&.error {
			border: 2px solid $red;
		}
	}

	input {
		display: none;
	}

	label {
		cursor: pointer;
		font-weight: 400;
	}
}

.loan-app-form-control {
	margin: 0 0 2rem 0;

	label {
		color: $black;
		font-weight: 400;
		display: block;
		margin-bottom: 0.6rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		.required-label {
			font-weight: 400;
			font-size: 12px;
			color: gray;
		}
	}

	input {
		font-family: $global-font;
		width: 100%;
		max-width: 750px;
	}

	select {
		padding: 6px 54px 6px 10px;

		/* Hide select styles and wrap "select" elements
		in a "div" with the "select-wrapper" class instead */
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
	}

	select::-ms-expand {
		display: none;
	}

	.radio-input-container {
		position: relative;
		cursor: pointer;
		padding-left: 2rem;
		margin-right: 2rem;
		display: inline-block;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
	}

	.custom-radio {
		margin-top: 0.2rem;
		position: absolute;
		top: 0;
		left: 0;
		height: 1.7rem;
		width: 1.7rem;
		background-color: white;
		border-radius: 50%;
		border: 2px solid $nbkc-blue;
	}

	.radio-input-container:hover input~.custom-radio {
		background-color: white;
	}

	.radio-input-container input:checked~.custom-radio {
		background-color: white;
	}

	.custom-radio::after {
		content: '';
		position: absolute;
		display: none;
	}

	.radio-input-container .custom-radio::after {
		top: 0.2rem;
		left: 0.2rem;
		width: 0.9rem;
		height: 0.9rem;
		border-radius: 50%;
		background: $nbkc-blue;
	}

	.radio-input-container input:checked~.custom-radio::after {
		display: block;
	}

	.loan-app-required {
		position: relative;

		&.loan-app-touched {
			&.loan-app-invalid::before {
				border-color: $red $red transparent transparent;
			}

			&.loan-app-invalid {
				input,
				select {
					border: 1px solid $red;
				}

				.field-error-text {
					display: inherit;

					&.field-error-text-table {
						display: table;
					}
				}
			}
		}

		&.loan-app-valid::before {
			visibility: hidden;
		}

		&.loan-app-valid::after {
			visibility: hidden;
		}

		&::before {
			font-size: 16px;
			position: absolute;
			color: #fff;
			right: 0;
			top: 0;
			z-index: 1;
			border-style: solid;
			border-width: 12px;
			border-color: #b9b9b9 #b9b9b9 transparent transparent;
			box-sizing: border-box;
			height: 12px;
			width: 12px;
			float: left;
			content: "";
			pointer-events: none;
			opacity: 0.75;
		}

		&::after {
			font-family: 'Montserrat', 'Open Sans', sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: #fff;
			right: 3px;
			position: absolute;
			top: 0px;
			content: "*";
			z-index: 2;
			pointer-events: none;
		}

		&.loan-app-invalid.loan-app-focused {
			.field-error-text {
				display: none;
			}
		}
	}

	.select-wrapper {
		position: relative;

		&::before {
			position: absolute;
			top: 3px;
			right: 10px;
			pointer-events: none;
			display: block;
			content: "\f078";
			font-weight: 600;
			font-family: 'Font Awesome 6 Pro', sans-serif;
			border: 7px solid transparent;
			transition: transform 250ms;
			z-index: 1;
		}

		&.focused {
			&::before {
				transform: rotate(-180deg);
			}
		}
	}
}

.loan-app-required-radio {
	.radio-input-container:last-child {
		overflow: visible;
		vertical-align: top;

		&::before {
			font-family: 'Montserrat', 'Open Sans', sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: $red;
			right: -15px;
			position: absolute;
			top: 4px;
			content: "*";
			z-index: 2;
			pointer-events: none;
		}
	}

	&.loan-app-valid {
		.radio-input-container:last-child {
			&::before {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 576px) {
	.radio-input-container {
		display: block;
	}
}

﻿@import 'animations';
@import 'colors';
@import 'app-variables';

@media only screen and (max-width: 1256px) {
	.nbkc {
		.radio-button-label {
			&.ellipsis {
				max-width: calc((100vw - #{$leftNavWidth} - 47rem) / 3);
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.nbkc {
		.loanapp-main {
			top: $shortHeaderHeight;
		}

		.equal-housing-lender-container {
			.equal-housing-lender-logo {
				width: 52px;
				padding-left: 5px;
			}
		}

		.radio-button-label {
			&.ellipsis {
				max-width: calc((100vw - #{$leftNavTabletWidth} - 47rem) / 3);
			}
		}
	}
}

@media only screen and (max-width: 959px) {
	.nbkc {
		// undo responsiveness changes included with angular-material by default
		md-toast {
			left: inherit;
			right: 2.5rem;
			bottom: 2.5rem;
			width: auto;
		}
	}
}

@media only screen and (max-width: 575px) {
	body {
		font-size: 15px;
	}

	.nbkc {
		.equal-housing-lender-container {
			.equal-housing-lender-logo {
				width: 100px;
				padding-left: 0;
			}
		}

		.loanapp-main {
			width: 100%;
		}

		md-toast {
			width: 100%;
			max-width: calc(100% - 6rem);
			bottom: 2rem;
			right: inherit;
			padding: 0 2.5rem 0 2rem;
		}

		.radio-button-label {
			&.ellipsis {
				max-width: calc((100vw - 13.5rem) / 3);
			}
		}
	}

	.full-width {
		width: 100%;
	}

	.screen-overlay {
		position: fixed;
		left: 0;
		top: $shortHeaderHeight;
		height: calc(100vh - #{$shortHeaderHeight});
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 150;
	}

	.main-menu-item {
		padding: 10px 21px;

		&.blue {
			background-color: $nbkc-blue;
			color: white;
		}

		.bold {
			font-weight: bold;
		}

		label {
			margin-left: 15px;
			font-size: 1em;
		}

		.status-icon {
			color: $light-gray;
			float: right;

			&.valid {
				color: $green;
			}

			&.invalid {
				color: $red;
			}
		}

		.in-line-div {
			display: inline;
			margin-left: auto;
		}
	}

	.overlay-hamburger {
		background-color: rgba(0, 0, 0, 0.7);

		a {
			color: white !important;
			transition: color all linear 0.5s;
		}
	}

	.top-level.clearfix {
		transition: background-color linear 0.5s;
		padding: 0;
	}

	nav {
		.row {
			margin-right: 0;
		}
	}

	.floating-mobile-help {
		position: fixed;
		right: 25px;
		bottom: 25px;
		width: 40px;
		height: 40px;
		background-color: $nbkc-blue;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
		border-radius: 25px;
		color: white;
		text-align: center;
		z-index: 100;

		.fa-question {
			margin-top: 10px;
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 576px) {
	.nbkc {
		.radio-button-label {
			&.ellipsis {
				max-width: calc(100vw - 13.5rem);
				width: auto;
			}
		}

		.nav-main-menu {
			.equal-housing-lender-container {
				.equal-housing-lender-logo {
					width: 100px;
					padding-left: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 479px) {
	body {
		font-size: 14px;
	}
}

@import '../colors';

.mat-checkbox {
	font-family: "GT Walsheim", "Arial", sans-serif !important;
}

.big {
	.mat-checkbox-label {
		font-weight: 400;
	}

	.mat-checkbox-inner-container {
		height: 25px;
		width: 25px;
		margin-right: 10px;
	}

	.mat-checkbox {
		.mat-checkbox-ripple {
			left: calc(50% - 25px);
			top: calc(50% - 25px);
			height: 50px;
			width: 50px;
		}
	}
}

.wrap-text{
	.mat-checkbox-layout {
		white-space: initial;
	}
}

.demographics-section {
	.mat-checkbox-label {
		@media only screen and (max-width: 575px) {
			// max-width: calc(100vw - 7rem - 25px);
			white-space: normal;
			font-size: 15px;
		}
	}
}

.borrowers-section {
	.mat-checkbox-label {
		@media only screen and (max-width: 575px) {
			// max-width: calc(100vw - 7rem - 25px);
			white-space: normal;
			font-size: 15px;
		}

		white-space: normal;
	}
}

$nbkc-blue: #1075F1;;
$light-blue: #338fd8;
$light-gray: #c9c9c9;
$medium-gray: #8D9192;
$dark-gray: #5a5a5a;
$darkest-gray: #212121;
$red: #dd2c00;
$bright-red: #FF2C00;
$green: #00ab56;
$yellow: #f9e15b;
$black: #000000;

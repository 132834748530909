@import 'app/styles/loader';
@import 'app/styles/fonts/gt-walsheim';
@import 'app/styles/font-awesome/all';
@import 'app/styles/nbkc-app';
@import 'app/styles/forms';
@import 'app/styles/mobile';
@import 'app/styles/card';
@import 'app/styles/modern-browser';
@import 'app/styles/loan-app-modal';
@import 'app/styles/button';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'app/styles/material/nbkc-theme';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');




@import '../colors';
@import '../app-variables';

.mat-mdc-button-base {
    font-family: $global-font !important;
}

.mat-mdc-button-base{
	&.rounded-material-button {
		border-radius: 200px;
	}
}

.blue-material-button{
	 &.mat-mdc-raised-button:not(:disabled) {
		 background-color: $nbkc-blue;
		 border-radius: 150px;
		 color: white;
	 }
 }

.light-blue-material-button {
	&.mat-mdc-raised-button.mat-primary {
		background-color: $light-blue;
		color: white;
	}
}

.bright-red-material-button {
	&.mat-mdc-raised-button.mat-primary {
		background-color: $bright-red;
		color: white;
	}
}

.red-material-button{
	&.mat-mdc-raised-button.mat-primary {
		background-color: $red ;
		color: white ;
	}
}

.material-button-no-padding{
	&.mat-button {
		padding: 0;
	}
}

.conversation-card-btn.mdc-button.mdc-button-raised.mat-mdc-raised-button.mat-mdc-button-persistent-ripple {
	padding: 3rem;
}

input:focus.mat-input-element {
	border:none;
}
input:hover.mat-input-element {
	border:none;
}

.no-bottom-padding {
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
}


.short.mat-form-field-appearance-outline{
	&.mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding: 0.3em 0 0.5em 0; }
	&.mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top: -1.4em; }

	&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		transform: translateY(-1.1em) scale(.75) !important;
		width: 133.33333% !important;
	}


	.mat-form-field-outline-thick {
		.mat-form-field-outline-end, .mat-form-field-outline-gap, .mat-form-field-outline-start{
			border-width: 1px;
		}
	}
	&.mat-focused{
		.mat-form-field-outline-thick {
			.mat-form-field-outline-end, .mat-form-field-outline-gap, .mat-form-field-outline-start{
				border-width: 2px;
			}
		}
	}

	.mat-form-field-subscript-wrapper{
		margin-top: .5em;
	}

	.mat-form-field-suffix .mat-icon {
		font-size: 100%;
	}

	.mat-form-field-suffix {
		top: 0;
	}

}

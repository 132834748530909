@import 'app-variables';
@import 'colors';

html {
	font-size: 10px;
}

html,
body {
	height: 100%;
}

.ag-theme-material .ag-icon-checkbox-checked {
	color: $nbkc-blue !important;
}

body.nbkc {
	margin: 0;
	color: $black;
	background: #fff;
	font-size: 16px;
	font-family: $global-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.always-body-scroll.modal-open {
	padding-right: 0 !important;
}

.always-body-scroll {
	overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 10px;
	font-weight: 600;
	font-family: $global-font;
}

h1 {
	font-size: 24px;
	line-height: 32px;
}

h2,
h3 {
	font-size: 18px;
	line-height: 23px;
}

h4,
h5,
h6 {
	font-size: 14px;
}

nav {
	padding: 0;
}

.md-toast-animating {
	overflow: inherit !important;
}

input {
	border: 1px solid #cdcdcd;
}

input::-ms-clear {
	display: none;
}

input:hover {
	border: 1px solid #b9b9b9;
}

input:focus {
	border: 1px solid #4285f4;
}

input.error {
	background: #ffd9d9;
	border: 1px solid #f00;
}

::-webkit-input-placeholder {
	/* Chrome */
	color: #bbb !important;
	transition: opacity 250ms ease-in-out;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #bbb !important;
	opacity: 1;
	transition: opacity 250ms ease-in-out;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb !important;
	transition: opacity 250ms ease-in-out;
}

:-moz-placeholder {
	/* Firefox 4 - 18 */
	color: #bbb !important;
	opacity: 1;
	transition: opacity 250ms ease-in-out;
}

:focus {
	outline: none;
}

:focus::-moz-placeholder {
	opacity: 0.5;
}

:focus::-webkit-input-placeholder {
	opacity: 0.5;
}

:focus:-ms-input-placeholder {
	opacity: 0.5;
}

:focus:-moz-placeholder {
	opacity: 0.5;
}

label {
	font-weight: 600;
}

a,
span[data-click],
span[data-trigger] {
	color: #075693;
	cursor: pointer;
}

a:hover,
span[data-click]:hover,
span[data-trigger]:hover {
	color: #2a6496;
	text-decoration: underline;
}

a:not([href]) {
	color: #075693;
	text-decoration: none;

	&:hover {
		color: #2a6496;
		text-decoration: underline;
	}
}

li.active>a,
li.active>a:hover {
	color: #444;
	font-weight: 600;
}

.align-center {
    text-align: center;
}

html,
body {
	height: 100%;
}

.nbkc {

	.row {
		margin-right: 0;
	}

	.spoof-control {
		visibility: hidden;
	}

	.form-control {
		height: 40px;
		font-size: 16px;
		box-shadow: none;
		border-radius: 0;
		color: $black;

		&:focus {
			box-shadow: none;
			border-color: $nbkc-blue !important;
		}
	}

	.inline {
		display: inline-block;
		white-space: nowrap;
		vertical-align: top;

		.form-control {
			width: 100%;
			box-shadow: none;
			font-size: 14px;
			color: $black;
		}
	}

	span.next-button-text {
		margin-right: 10px;
	}

	.status-checkbox-title {
		display: inline-block;
		vertical-align: middle;
		margin-left: 18px;
		font-weight: 700;
	}

	.loan-app-separator {
		height: 1px;
		background: #eaeaea;
		margin: 1rem 0;
		width: 100%;

		&.spaced {
			margin: 1rem 1.5rem 2.5rem;
		}

		&.big-spaced {
			margin: 3rem 1.5rem 3rem;
		}

		&.darker {
			background: #d2cece;
		}
	}

	md-switch {
		margin: 9px 0;
		display: inline;

		.md-container {
			margin: 0;
		}
	}

	.toggle-container {
		display: flex;
		align-items: center;

		.toggle {
			display: flex;
			align-items: center;

			md-switch {
				margin: 9px 0 0 0;
			}
		}
	}

	.clickable {
		cursor: pointer;
	}

	.bold {
		font-weight: 600;
	}

	.hint-text {
		font-size: 12px;
	}

	.yes-text {
		margin-left: 1rem;
	}

	.no-text {
		margin-right: 1rem;
	}

	.toggle-button-label {
		margin-bottom: 0;
		margin-right: 3rem;
		white-space: normal !important;
	}

	.radio-button-label {
		text-transform: capitalize;
		margin-left: 0.5rem;
		font-weight: 400;

		&.ellipsis {
			max-width: 175px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.equal-housing-lender-container {
		margin: auto auto 3rem;
		padding-top: 3rem;

		.equal-housing-lender-logo {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 100px;
			height: 26.14px;
			opacity: 0.8;
		}

		.equal-housing-lender-text {
			margin-top: 3px;
			text-align: center;
			font-size: 12px;
			color: #444;

			a {
				color: #444;
				&:focus,
				&:hover {
					color: $light-blue
				}
			}

		}
	}

	header.main,
	nav.main-menu {
		ol,
		ul {
			list-style: none;
		}
	}

	nav.main-menu {
		position: fixed;
		z-index: 200;
		top: $tallHeaderHeight;
		right: 0;
		width: 230px;
		background-color: #fff;
		padding: 2rem 3rem;
		border: 1px solid #ddd;
		border-top: 0;

		ul {
			float: left;
			width: 30%;

			&.profile-menu {
				display: block !important;
				width: 100% !important;

				li.list-section-header {
					font-family: $global-font;
					font-weight: 700;
					font-size: 0.875em;
					margin-bottom: 15px;
					letter-spacing: 0.05em;
					word-wrap: break-word;
				}

				li>a {
					font-family: $global-font;
					font-weight: 400;
					white-space: nowrap;
					line-height: 1.6;
					&:focus,
					&:hover {
						text-decoration: underline;
					}
				}
			}

			&:first-child {
				margin-left: 0;
				padding-left: 0;
			}
		}

		form {
			clear: both;
			width: 100%;
			margin: 0 auto;
			padding-top: 30px;

			input,
			button {
				float: left;
				height: 40px;
			}

			input {
				width: 77%;
				padding: 0 15px;
				border: 1px solid #ddd;
				border-right: 0;
				margin-left: 5%;
			}

			button {
				width: 50px;
				color: #a1a1a1;
				font-size: 1.25em;
				border: 1px solid #ddd;
				border-left: none;
				background: none;
				outline: 0;
			}
		}

		.menu-item-container {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	md-toast {
		position: fixed;
		right: 2.5rem;
		bottom: 2.5rem;
		z-index: 2000;

		&.md-capsule {
			border-radius: 5px;
		}

		.md-toast-content {
			border-radius: 5px;

			.md-action.md-button {
				font-weight: 600;

				&:hover {
					background: rgba(158, 158, 158, 0.4);
				}
			}
		}

		&.nbkc-success {
			.md-toast-content {
				background: #00ab56;
			}
		}

		&.nbkc-error {
			.md-toast-content {
				background: #ba0013;
			}
		}
	}

	.loanapp-main {
		background: #fff;
		display: block;
		clear: both;
		min-height: calc(100% - 140px);
		position: relative;
		top: $tallHeaderHeight;
	}

	.vertical-line {
		border-right: 1px solid #c5c5c5;
		height: 1.4rem;
		width: 1.5rem;
	}

	.status-check-box {
		border: 1px solid $dark-gray;
		border-radius: 3px;
		width: 30px;
		height: 30px;
		display: inline-block;
		background: #fff;
		color: transparent;
		position: relative;

		.status-box-check {
			margin-left: 8px;
			margin-top: 7px;

			&.transparent {
				color: transparent;
			}
		}

		&.status-check-box-valid {
			color: $green;
		}

		&.status-check-box-error {
			color: $red;
		}
	}
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
	display: none;
}

.ng-animate.no-animate {
	display: none !important;
}

@keyframes blink {
	/**
     * At the start of the animation the dot
     * has an opacity of .2
     */
	0% {
		opacity: 0.2;
	}

	/**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
	20% {
		opacity: 1;
	}

	/**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
	100% {
		opacity: 0.2;
	}
}

.no-scroll {
	overflow: hidden !important;
}

.pointer-cursor {
	cursor: pointer;
}

.saving {
	span {
		/**
		 * Use the blink animation, which is defined above
		 */
		animation-name: blink;

		/**
		 * The animation should take 1.4 seconds
		 */
		animation-duration: 1.4s;

		/**
		 * It will repeat itself forever
		 */
		animation-iteration-count: infinite;

		/**
		 * This makes sure that the starting style (opacity: .2)
		 * of the animation is applied before the animation starts.
		 * Otherwise we would see a short flash or would have
		 * to set the default styling of the dots to the same
		 * as the animation. Same applies for the ending styles.
		 */
		animation-fill-mode: both;
	}

	span:nth-child(2) {
		/**
		 * Starts the animation of the third dot
		 * with a delay of .2s, otherwise all dots
		 * would animate at the same time
		 */
		animation-delay: 0.2s;
	}

	span:nth-child(3) {
		/**
		 * Starts the animation of the third dot
		 * with a delay of .4s, otherwise all dots
		 * would animate at the same time
		 */
		animation-delay: 0.4s;
	}
}

.sr-only {
	position: fixed;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.loan-app-action-button {
	cursor: pointer;
	border: none;

	&.loan-app-button-text {
		color: #474747;
		background: transparent;

		&.cdk-keyboard-focused {
			text-decoration: underline;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

.mat-radio-button {
	margin-right: 16px;

	&:last-child {
		margin-right: 0;
	}

	label {
		font-weight: 400;
		font-family: 'GT Walsheim', 'Arial', sans-serif;
	}
}
